<template>
  <div class="bp-service">
    <div class="container">
      <div class="Header-item"><Header /></div>
      <div class=""><Carousel /></div>
      <div class="bp-bread h5_none">
        <el-breadcrumb class="bp-bread-box" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">{{$t("lang.bpCoach.breadcrumb.toTitle")}}</el-breadcrumb-item>
          <el-breadcrumb-item><a href="/">{{$t("lang.bpCoach.breadcrumb.selfTitle")}}</a></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="bp-tip h5_none">
        <img :src='$t("lang.bpCoach.tip.pcUrl")' alt="计划书">
      </div>
      <div class="bp-tip-web web_none">
        <img :src='$t("lang.bpCoach.tip.h5Url")' alt="计划书">
      </div>
      <div class="bp-content">
        <!-- BP定制解决方案 -->
        <div class="content-box">
          <h3 class="content-title">{{$t("lang.bpCoach.contentTitle.solution")}}</h3>
          <div class="main">
            <div class="item" v-for="(item,index) in $t('lang.bpCoach.solution')" :key="index">
              <div class="item-box">
                <img :src="item.url" alt="" class="logo-img" />
                <div>
                  <h5 class="item-title">{{item.title}}</h5>
                  <p class="item-text">{{item.text}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 服务保障 -->
        <div class="content-box content-two">
          <h3 class="content-title">{{$t("lang.bpCoach.contentTitle.server")}}</h3>
          <div class="main h5_none">
            <div class="item-server" v-for="(item,index) in  $t('lang.bpCoach.server.pcUrl')" :key="index">
              <img :src="item.url" alt="" class="item-img" />
            </div>
          </div>
          <div class="carousel-server web_none">
            <swiper class="swiper" :options="swiperOption" :key="keys">
              <swiper-slide class="swiper-slide" v-for="(item,index) in $t('lang.bpCoach.server.h5Url')" :key="index">
                <img :src="item.url" alt="服务保障">
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
        <!-- BP定制服务流程 -->
        <div class="content-box h5_none">
          <h3 class="content-title">{{$t("lang.bpCoach.contentTitle.custom")}}</h3>
          <div class="main">
            <div class="item-custom">
              <img :src="$t('lang.bpCoach.custom.pcUrl')" alt="" class="item-img" />
            </div>
          </div>
        </div>
        <!-- BP定制服务流程 -->
        <div class="content-custom web_none">
          <h3>{{$t("lang.bpCoach.contentTitle.custom")}}</h3>
          <div class="item-custom-box">
            <div class="item-custom" v-for="(item,index) in $t('lang.bpCoach.custom.h5Url')" :key="index">
              <img :src="item.url" alt="服务流程">
              <div class="custom-info-box">
                <div class="custom-title">{{item.title}}</div>
                <div class="custom-info">{{item.text}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- BP定制服务案例 -->
        <div class="content-box h5_none">
          <h3 class="content-title">{{$t("lang.bpCoach.contentTitle.case")}}</h3>
          <div class="main">
            <div class="item-case">
              <img :src="$t('lang.bpCoach.case.pcUrl')" alt="" class="item-img" />
            </div>
          </div>
        </div>
        <div class="content-box-web web_none">
          <h3>{{$t("lang.bpCoach.contentTitle.case")}}</h3>
          <div class="carousel-case">
            <swiper class="swiper" :options="swiperOptionCase" :key="keys">
              <swiper-slide class="swiper-slide" v-for="(item,index) in $t('lang.bpCoach.case.h5Url')" :key="index">
                <img :src="item.url" alt="案例">
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
        <!-- 合作投资机构 -->
        <div class="content_organ h5_none">
          <h1>{{$t("lang.bpCoach.contentTitle.organ")}}</h1>
          <img src="../assets/bp/bp_organ.png" :alt='$t("lang.bpCoach.contentTitle.organ")'>
        </div>
        <div class="content_organ_web web_none">
          <h3>{{$t("lang.bpCoach.contentTitle.organ")}}</h3>
          <img src="../assets/bp/bp_organ_web.png" :alt='$t("lang.bpCoach.contentTitle.organ")'>
        </div>
      </div>
    </div>
    <Copyright />
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Carousel from '@/components/Carousel/Carousel.vue'
import Copyright from '@/components/Copyright/Copyright.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'
import { mapGetters } from 'vuex'

export default {
  name: 'Incubation',
  components: {
    Header,
    Carousel,
    Copyright,
    swiper,
    swiperSlide

  },
  data () {
    return {
      // 轮播图
      swiperOption: {
        loop: true, // 循环模式选项
        autoplay: true, // 是否自动播放
        spaceBetween: -140,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets'
        }
      },
      swiperOptionCase: {
        loop: true, // 循环模式选项
        autoplay: true, // 是否自动播放
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets'
        }
      },
      keys: '100'
    }
  },
  computed: {
    ...mapGetters(['getLangIndex'])
  },
  watch: {
    getLangIndex (to, from) {
      this.keys = Math.random() * 1000
    }
  },
  created () {
    this.$store.dispatch('setNavIndex', 2)
  }
}
</script>
<style scoped lang="less">
.bp-service{
  background: #efefef;
  .container{
    .Header-item{
      position: absolute;
      z-index: 10;
      width: 100%;
    }
  }
}
.bp-bread /deep/.el-breadcrumb__item{
    font-size: 16px;
    color: #888;
}
.bp-bread /deep/.is-link{
  font-weight: normal;
  color: #888;
}
.bp-bread{
  width: 1200px;
  margin: 0 auto;
  .bp-bread-box{
    line-height: 60px;
    height: 60px;
  }
}
.bp-tip{
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-content: center;
  img{
    // width: 1662px;
    width: 1300px;
  }
}
.web_none{
  display: none;
}
.bp-content {
  .content-box {
    padding-top: 30px;
    // width: 1612px;
    max-width: 1200px;
    margin: auto;
    // 模块标题
    .content-title {
      // margin-bottom: 2vh;
      border-left: 5px solid #17cad0;
      // font-size: 25px;
      padding-left: 16px;
      margin-bottom: 30px;
      color: #222;
    }
    //
    .main {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      // align-items: center;
      .item:nth-child(1),.item:nth-child(2){
        margin-bottom: 30px;
      }
      .item {
        width: 50%;
        display: flex;
        justify-content: center;
        .item-box {
          // width: 90%;
          display: flex;
          div{
            width: 400px;
            .item-title {
              margin: 7px 0 15px;
              font-size: 18px;
              color: #222;
              // margin-bottom: 1vh;
            }
            .item-text {
              // font-size: 17px;
              line-height: 25px;
              color: #999;
            }
          }
          .logo-img {
            width: 120px;
            height: 120px;
            margin-right: 40px;
            // margin: 0 20px 0 50px;
          }
        }
      }
      .item-img {
        width: 100%;
      }
      .item-server {
        width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .item-custom{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1000px;
        margin: 30px 0;
      }
      .item-case{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1200px;
      }
    }
  }
  .content-two{
    padding-top: 80px;
    .content-title{
      margin-bottom: 50px;
    }
  }
  .content_organ{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 80px 0;
    h1{
      margin-bottom: 30px;
      color: #222;
    }
    img{
      width: 1200px;
    }
  }
}
</style>
